import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['welcome']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'owner',
    canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/owner/owner.module').then( m => m.OwnerPageModule)
  },
  {
    path: 'bike-registration',
    canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/bike-registration/bike-registration.module').then( m => m.BikeRegistrationPageModule)
  },
  {
    path: 'bike-registration/:type',
    canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/bike-registration/bike-registration.module').then( m => m.BikeRegistrationPageModule)
  },
  {
    path: 'bike/:id',
    canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/bike/bike.module').then( m => m.BikePageModule)
  },
  {
    path: 'technical',
    canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/technical/technical.module').then( m => m.TechnicalPageModule)
  },
  {
    path: 'my-bikes',
    loadChildren: () => import('./pages/my-bikes/my-bikes.module').then( m => m.MyBikesPageModule)
  }
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
