import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { LoadingController, ModalController, MenuController, PickerController } from '@ionic/angular';
import { AppDataService } from '../services/app-data.service';
import { NavigationComponent } from '../components/navigation/navigation.component';
import { ScannerComponent } from '../components/scanner/scanner.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public deferredPrompt: any;
  public countryList = [{
    name: "Afghanistan",
    isd: "93",
    code1: "AFG",
    code2: "AF"
},
{
    name: "Albania",
    isd: "355",
    code1: "ALB",
    code2: "AL"
},
{
    name: "Algeria",
    isd: "213",
    code1: "DZA",
    code2: "DZ"
},
{
    name: "American Samoa",
    isd: "1684",
    code1: "ASM",
    code2: "AS"
},
{
    name: "Andorra",
    isd: "376",
    code1: "AND",
    code2: "AD"
},
{
    name: "Angola",
    isd: "244",
    code1: "AGO",
    code2: "AO"
},
{
    name: "Anguilla",
    isd: "1264",
    code1: "AIA",
    code2: "AI"
},
{
    name: "Antarctica",
    isd: "672",
    code1: "ATA",
    code2: "AQ"
},
{
    name: "Antigua and Barbuda",
    isd: "1268",
    code1: "ATG",
    code2: "AG"
},
{
    name: "Argentina",
    isd: "54",
    code1: "ARG",
    code2: "AR"
},
{
    name: "Armenia",
    isd: "374",
    code1: "ARM",
    code2: "AM"
},
{
    name: "Aruba",
    isd: "297",
    code1: "ABW",
    code2: "AW"
},
{
    name: "Australia",
    isd: "61",
    code1: "AUS",
    code2: "AU"
},
{
    name: "Austria",
    isd: "43",
    code1: "AUT",
    code2: "AT"
},
{
    name: "Azerbaijan",
    isd: "994",
    code1: "AZE",
    code2: "AZ"
},
{
    name: "Bahamas",
    isd: "1242",
    code1: "BHS",
    code2: "BS"
},
{
    name: "Bahrain",
    isd: "973",
    code1: "BHR",
    code2: "BH"
},
{
    name: "Bangladesh",
    isd: "880",
    code1: "BGD",
    code2: "BD"
},
{
    name: "Barbados",
    isd: "1246",
    code1: "BRB",
    code2: "BB"
},
{
    name: "Belarus",
    isd: "375",
    code1: "BLR",
    code2: "BY"
},
{
    name: "Belgium",
    isd: "32",
    code1: "BEL",
    code2: "BE"
},
{
    name: "Belize",
    isd: "501",
    code1: "BLZ",
    code2: "BZ"
},
{
    name: "Benin",
    isd: "229",
    code1: "BEN",
    code2: "BJ"
},
{
    name: "Bermuda",
    isd: "1441",
    code1: "BMU",
    code2: "BM"
},
{
    name: "Bhutan",
    isd: "975",
    code1: "BTN",
    code2: "BT"
},
{
    name: "Bolivia",
    isd: "591",
    code1: "BOL",
    code2: "BO"
},
{
    name: "Bosnia and Herzegovina",
    isd: "387",
    code1: "BIH",
    code2: "BA"
},
{
    name: "Botswana",
    isd: "267",
    code1: "BWA",
    code2: "BW"
},
{
    name: "Brazil",
    isd: "55",
    code1: "BRA",
    code2: "BR"
},
{
    name: "British Indian Ocean Territory",
    isd: "246",
    code1: "IOT",
    code2: "IO"
},
{
    name: "British Virgin Islands",
    isd: "1284",
    code1: "VGB",
    code2: "VG"
},
{
    name: "Brunei",
    isd: "673",
    code1: "BRN",
    code2: "BN"
},
{
    name: "Bulgaria",
    isd: "359",
    code1: "BGR",
    code2: "BG"
},
{
    name: "Burkina Faso",
    isd: "226",
    code1: "BFA",
    code2: "BF"
},
{
    name: "Myanmar",
    isd: "95",
    code1: "MMR",
    code2: "MM"
},
{
    name: "Burundi",
    isd: "257",
    code1: "BDI",
    code2: "BI"
},
{
    name: "Cambodia",
    isd: "855",
    code1: "KHM",
    code2: "KH"
},
{
    name: "Cameroon",
    isd: "237",
    code1: "CMR",
    code2: "CM"
},
{
    name: "Canada",
    isd: "1",
    code1: "CAN",
    code2: "CA"
},
{
    name: "Cape Verde",
    isd: "238",
    code1: "CPV",
    code2: "CV"
},
{
    name: "Cayman Islands",
    isd: "1345",
    code1: "CYM",
    code2: "KY"
},
{
    name: "Central African Republic",
    isd: "236",
    code1: "CAF",
    code2: "CF"
},
{
    name: "Chad",
    isd: "235",
    code1: "TCD",
    code2: "TD"
},
{
    name: "Chile",
    isd: "56",
    code1: "CHL",
    code2: "CL"
},
{
    name: "China",
    isd: "86",
    code1: "CHN",
    code2: "CN"
},
{
    name: "Christmas Island",
    isd: "61",
    code1: "CXR",
    code2: "CX"
},
{
    name: "Cocos Islands",
    isd: "61",
    code1: "CCK",
    code2: "CC"
},
{
    name: "Colombia",
    isd: "57",
    code1: "COL",
    code2: "CO"
},
{
    name: "Comoros",
    isd: "269",
    code1: "COM",
    code2: "KM"
},
{
    name: "Republic of the Congo",
    isd: "242",
    code1: "COG",
    code2: "CG"
},
{
    name: "Democratic Republic of the Congo",
    isd: "243",
    code1: "COD",
    code2: "CD"
},
{
    name: "Cook Islands",
    isd: "682",
    code1: "COK",
    code2: "CK"
},
{
    name: "Costa Rica",
    isd: "506",
    code1: "CRI",
    code2: "CR"
},
{
    name: "Croatia",
    isd: "385",
    code1: "HRV",
    code2: "HR"
},
{
    name: "Cuba",
    isd: "53",
    code1: "CUB",
    code2: "CU"
},
{
    name: "Curacao",
    isd: "599",
    code1: "CUW",
    code2: "CW"
},
{
    name: "Cyprus",
    isd: "357",
    code1: "CYP",
    code2: "CY"
},
{
    name: "Czech Republic",
    isd: "420",
    code1: "CZE",
    code2: "CZ"
},
{
    name: "Denmark",
    isd: "45",
    code1: "DNK",
    code2: "DK"
},
{
    name: "Djibouti",
    isd: "253",
    code1: "DJI",
    code2: "DJ"
},
{
    name: "Dominica",
    isd: "1767",
    code1: "DMA",
    code2: "DM"
},
{
    name: "Dominican Republic",
    isd: "1809",
    code1: "DOM",
    code2: "DO"
},
{
    name: "Dominican Republic",
    isd: "1829",
    code1: "DOM",
    code2: "DO"
},
{
    name: "Dominican Republic",
    isd: "1849",
    code1: "DOM",
    code2: "DO"
},
{
    name: "East Timor",
    isd: "670",
    code1: "TLS",
    code2: "TL"
},
{
    name: "Ecuador",
    isd: "593",
    code1: "ECU",
    code2: "EC"
},
{
    name: "Egypt",
    isd: "20",
    code1: "EGY",
    code2: "EG"
},
{
    name: "El Salvador",
    isd: "503",
    code1: "SLV",
    code2: "SV"
},
{
    name: "Equatorial Guinea",
    isd: "240",
    code1: "GNQ",
    code2: "GQ"
},
{
    name: "Eritrea",
    isd: "291",
    code1: "ERI",
    code2: "ER"
},
{
    name: "Estonia",
    isd: "372",
    code1: "EST",
    code2: "EE"
},
{
    name: "Ethiopia",
    isd: "251",
    code1: "ETH",
    code2: "ET"
},
{
    name: "Falkland Islands",
    isd: "500",
    code1: "FLK",
    code2: "FK"
},
{
    name: "Faroe Islands",
    isd: "298",
    code1: "FRO",
    code2: "FO"
},
{
    name: "Fiji",
    isd: "679",
    code1: "FJI",
    code2: "FJ"
},
{
    name: "Finland",
    isd: "358",
    code1: "FIN",
    code2: "FI"
},
{
    name: "France",
    isd: "33",
    code1: "FRA",
    code2: "FR"
},
{
    name: "French Polynesia",
    isd: "689",
    code1: "PYF",
    code2: "PF"
},
{
    name: "Gabon",
    isd: "241",
    code1: "GAB",
    code2: "GA"
},
{
    name: "Gambia",
    isd: "220",
    code1: "GMB",
    code2: "GM"
},
{
    name: "Georgia",
    isd: "995",
    code1: "GEO",
    code2: "GE"
},
{
    name: "Germany",
    isd: "49",
    code1: "DEU",
    code2: "DE"
},
{
    name: "Ghana",
    isd: "233",
    code1: "GHA",
    code2: "GH"
},
{
    name: "Gibraltar",
    isd: "350",
    code1: "GIB",
    code2: "GI"
},
{
    name: "Greece",
    isd: "30",
    code1: "GRC",
    code2: "GR"
},
{
    name: "Greenland",
    isd: "299",
    code1: "GRL",
    code2: "GL"
},
{
    name: "Grenada",
    isd: "1473",
    code1: "GRD",
    code2: "GD"
},
{
    name: "Guam",
    isd: "1671",
    code1: "GUM",
    code2: "GU"
},
{
    name: "Guatemala",
    isd: "502",
    code1: "GTM",
    code2: "GT"
},
{
    name: "Guernsey",
    isd: "441481",
    code1: "GGY",
    code2: "GG"
},
{
    name: "Guinea",
    isd: "224",
    code1: "GIN",
    code2: "GN"
},
{
    name: "Guinea-Bissau",
    isd: "245",
    code1: "GNB",
    code2: "GW"
},
{
    name: "Guyana",
    isd: "592",
    code1: "GUY",
    code2: "GY"
},
{
    name: "Haiti",
    isd: "509",
    code1: "HTI",
    code2: "HT"
},
{
    name: "Honduras",
    isd: "504",
    code1: "HND",
    code2: "HN"
},
{
    name: "Hong Kong",
    isd: "852",
    code1: "HKG",
    code2: "HK"
},
{
    name: "Hungary",
    isd: "36",
    code1: "HUN",
    code2: "HU"
},
{
    name: "Iceland",
    isd: "354",
    code1: "ISL",
    code2: "IS"
},
{
    name: "India",
    isd: "91",
    code1: "IND",
    code2: "IN"
},
{
    name: "Indonesia",
    isd: "62",
    code1: "IDN",
    code2: "ID"
},
{
    name: "Iran",
    isd: "98",
    code1: "IRN",
    code2: "IR"
},
{
    name: "Iraq",
    isd: "964",
    code1: "IRQ",
    code2: "IQ"
},
{
    name: "Ireland",
    isd: "353",
    code1: "IRL",
    code2: "IE"
},
{
    name: "Isle of Man",
    isd: "441624",
    code1: "IMN",
    code2: "IM"
},
{
    name: "Israel",
    isd: "972",
    code1: "ISR",
    code2: "IL"
},
{
    name: "Italy",
    isd: "39",
    code1: "ITA",
    code2: "IT"
},
{
    name: "Ivory Coast",
    isd: "225",
    code1: "CIV",
    code2: "CI"
},
{
    name: "Jamaica",
    isd: "1876",
    code1: "JAM",
    code2: "JM"
},
{
    name: "Japan",
    isd: "81",
    code1: "JPN",
    code2: "JP"
},
{
    name: "Jersey",
    isd: "441534",
    code1: "JEY",
    code2: "JE"
},
{
    name: "Jordan",
    isd: "962",
    code1: "JOR",
    code2: "JO"
},
{
    name: "Kazakhstan",
    isd: "7",
    code1: "KAZ",
    code2: "KZ"
},
{
    name: "Kenya",
    isd: "254",
    code1: "KEN",
    code2: "KE"
},
{
    name: "Kiribati",
    isd: "686",
    code1: "KIR",
    code2: "KI"
},
{
    name: "Kosovo",
    isd: "383",
    code1: "XKX",
    code2: "XK"
},
{
    name: "Kuwait",
    isd: "965",
    code1: "KWT",
    code2: "KW"
},
{
    name: "Kyrgyzstan",
    isd: "996",
    code1: "KGZ",
    code2: "KG"
},
{
    name: "Laos",
    isd: "856",
    code1: "LAO",
    code2: "LA"
},
{
    name: "Latvia",
    isd: "371",
    code1: "LVA",
    code2: "LV"
},
{
    name: "Lebanon",
    isd: "961",
    code1: "LBN",
    code2: "LB"
},
{
    name: "Lesotho",
    isd: "266",
    code1: "LSO",
    code2: "LS"
},
{
    name: "Liberia",
    isd: "231",
    code1: "LBR",
    code2: "LR"
},
{
    name: "Libya",
    isd: "218",
    code1: "LBY",
    code2: "LY"
},
{
    name: "Liechtenstein",
    isd: "423",
    code1: "LIE",
    code2: "LI"
},
{
    name: "Lithuania",
    isd: "370",
    code1: "LTU",
    code2: "LT"
},
{
    name: "Luxembourg",
    isd: "352",
    code1: "LUX",
    code2: "LU"
},
{
    name: "Macau",
    isd: "853",
    code1: "MAC",
    code2: "MO"
},
{
    name: "Macedonia",
    isd: "389",
    code1: "MKD",
    code2: "MK"
},
{
    name: "Madagascar",
    isd: "261",
    code1: "MDG",
    code2: "MG"
},
{
    name: "Malawi",
    isd: "265",
    code1: "MWI",
    code2: "MW"
},
{
    name: "Malaysia",
    isd: "60",
    code1: "MYS",
    code2: "MY"
},
{
    name: "Maldives",
    isd: "960",
    code1: "MDV",
    code2: "MV"
},
{
    name: "Mali",
    isd: "223",
    code1: "MLI",
    code2: "ML"
},
{
    name: "Malta",
    isd: "356",
    code1: "MLT",
    code2: "MT"
},
{
    name: "Marshall Islands",
    isd: "692",
    code1: "MHL",
    code2: "MH"
},
{
    name: "Mauritania",
    isd: "222",
    code1: "MRT",
    code2: "MR"
},
{
    name: "Mauritius",
    isd: "230",
    code1: "MUS",
    code2: "MU"
},
{
    name: "Mayotte",
    isd: "262",
    code1: "MYT",
    code2: "YT"
},
{
    name: "Mexico",
    isd: "52",
    code1: "MEX",
    code2: "MX"
},
{
    name: "Micronesia",
    isd: "691",
    code1: "FSM",
    code2: "FM"
},
{
    name: "Moldova",
    isd: "373",
    code1: "MDA",
    code2: "MD"
},
{
    name: "Monaco",
    isd: "377",
    code1: "MCO",
    code2: "MC"
},
{
    name: "Mongolia",
    isd: "976",
    code1: "MNG",
    code2: "MN"
},
{
    name: "Montenegro",
    isd: "382",
    code1: "MNE",
    code2: "ME"
},
{
    name: "Montserrat",
    isd: "1664",
    code1: "MSR",
    code2: "MS"
},
{
    name: "Morocco",
    isd: "212",
    code1: "MAR",
    code2: "MA"
},
{
    name: "Mozambique",
    isd: "258",
    code1: "MOZ",
    code2: "MZ"
},
{
    name: "Namibia",
    isd: "264",
    code1: "NAM",
    code2: "NA"
},
{
    name: "Nauru",
    isd: "674",
    code1: "NRU",
    code2: "NR"
},
{
    name: "Nepal",
    isd: "977",
    code1: "NPL",
    code2: "NP"
},
{
    name: "Netherlands",
    isd: "31",
    code1: "NLD",
    code2: "NL"
},
{
    name: "Netherlands Antilles",
    isd: "599",
    code1: "ANT",
    code2: "AN"
},
{
    name: "New Caledonia",
    isd: "687",
    code1: "NCL",
    code2: "NC"
},
{
    name: "New Zealand",
    isd: "64",
    code1: "NZL",
    code2: "NZ"
},
{
    name: "Nicaragua",
    isd: "505",
    code1: "NIC",
    code2: "NI"
},
{
    name: "Niger",
    isd: "227",
    code1: "NER",
    code2: "NE"
},
{
    name: "Nigeria",
    isd: "234",
    code1: "NGA",
    code2: "NG"
},
{
    name: "Niue",
    isd: "683",
    code1: "NIU",
    code2: "NU"
},
{
    name: "Northern Mariana Islands",
    isd: "1670",
    code1: "MNP",
    code2: "MP"
},
{
    name: "North Korea",
    isd: "850",
    code1: "PRK",
    code2: "KP"
},
{
    name: "Norway",
    isd: "47",
    code1: "NOR",
    code2: "NO"
},
{
    name: "Oman",
    isd: "968",
    code1: "OMN",
    code2: "OM"
},
{
    name: "Pakistan",
    isd: "92",
    code1: "PAK",
    code2: "PK"
},
{
    name: "Palau",
    isd: "680",
    code1: "PLW",
    code2: "PW"
},
{
    name: "Palestine",
    isd: "970",
    code1: "PSE",
    code2: "PS"
},
{
    name: "Panama",
    isd: "507",
    code1: "PAN",
    code2: "PA"
},
{
    name: "Papua New Guinea",
    isd: "675",
    code1: "PNG",
    code2: "PG"
},
{
    name: "Paraguay",
    isd: "595",
    code1: "PRY",
    code2: "PY"
},
{
    name: "Peru",
    isd: "51",
    code1: "PER",
    code2: "PE"
},
{
    name: "Philippines",
    isd: "63",
    code1: "PHL",
    code2: "PH"
},
{
    name: "Pitcairn",
    isd: "64",
    code1: "PCN",
    code2: "PN"
},
{
    name: "Poland",
    isd: "48",
    code1: "POL",
    code2: "PL"
},
{
    name: "Portugal",
    isd: "351",
    code1: "PRT",
    code2: "PT"
},
{
    name: "Puerto Rico",
    isd: "1787",
    code1: "PRI",
    code2: "PR"
},
{
    name: "Puerto Rico",
    isd: "1939",
    code1: "PRI",
    code2: "PR"
},
{
    name: "Qatar",
    isd: "974",
    code1: "QAT",
    code2: "QA"
},
{
    name: "Reunion",
    isd: "262",
    code1: "REU",
    code2: "RE"
},
{
    name: "Romania",
    isd: "40",
    code1: "ROU",
    code2: "RO"
},
{
    name: "Russia",
    isd: "7",
    code1: "RUS",
    code2: "RU"
},
{
    name: "Rwanda",
    isd: "250",
    code1: "RWA",
    code2: "RW"
},
{
    name: "Saint Barthelemy",
    isd: "590",
    code1: "BLM",
    code2: "BL"
},
{
    name: "Samoa",
    isd: "685",
    code1: "WSM",
    code2: "WS"
},
{
    name: "San Marino",
    isd: "378",
    code1: "SMR",
    code2: "SM"
},
{
    name: "Sao Tome and Principe",
    isd: "239",
    code1: "STP",
    code2: "ST"
},
{
    name: "Saudi Arabia",
    isd: "966",
    code1: "SAU",
    code2: "SA"
},
{
    name: "Senegal",
    isd: "221",
    code1: "SEN",
    code2: "SN"
},
{
    name: "Serbia",
    isd: "381",
    code1: "SRB",
    code2: "RS"
},
{
    name: "Seychelles",
    isd: "248",
    code1: "SYC",
    code2: "SC"
},
{
    name: "Sierra Leone",
    isd: "232",
    code1: "SLE",
    code2: "SL"
},
{
    name: "Singapore",
    isd: "65",
    code1: "SGP",
    code2: "SG"
},
{
    name: "Sint Maarten",
    isd: "1721",
    code1: "SXM",
    code2: "SX"
},
{
    name: "Slovakia",
    isd: "421",
    code1: "SVK",
    code2: "SK"
},
{
    name: "Slovenia",
    isd: "386",
    code1: "SVN",
    code2: "SI"
},
{
    name: "Solomon Islands",
    isd: "677",
    code1: "SLB",
    code2: "SB"
},
{
    name: "Somalia",
    isd: "252",
    code1: "SOM",
    code2: "SO"
},
{
    name: "South Africa",
    isd: "27",
    code1: "ZAF",
    code2: "ZA"
},
{
    name: "South Korea",
    isd: "82",
    code1: "KOR",
    code2: "KR"
},
{
    name: "South Sudan",
    isd: "211",
    code1: "SSD",
    code2: "SS"
},
{
    name: "Spain",
    isd: "34",
    code1: "ESP",
    code2: "ES"
},
{
    name: "Sri Lanka",
    isd: "94",
    code1: "LKA",
    code2: "LK"
},
{
    name: "Saint Helena",
    isd: "290",
    code1: "SHN",
    code2: "SH"
},
{
    name: "Saint Kitts and Nevis",
    isd: "1869",
    code1: "KNA",
    code2: "KN"
},
{
    name: "Saint Lucia",
    isd: "1758",
    code1: "LCA",
    code2: "LC"
},
{
    name: "Saint Martin",
    isd: "590",
    code1: "MAF",
    code2: "MF"
},
{
    name: "Saint Pierre and Miquelon",
    isd: "508",
    code1: "SPM",
    code2: "PM"
},
{
    name: "Saint Vincent and the Grenadines",
    isd: "1784",
    code1: "VCT",
    code2: "VC"
},
{
    name: "Sudan",
    isd: "249",
    code1: "SDN",
    code2: "SD"
},
{
    name: "Suriname",
    isd: "597",
    code1: "SUR",
    code2: "SR"
},
{
    name: "Svalbard and Jan Mayen",
    isd: "47",
    code1: "SJM",
    code2: "SJ"
},
{
    name: "Swaziland",
    isd: "268",
    code1: "SWZ",
    code2: "SZ"
},
{
    name: "Sweden",
    isd: "46",
    code1: "SWE",
    code2: "SE"
},
{
    name: "Switzerland",
    isd: "41",
    code1: "CHE",
    code2: "CH"
},
{
    name: "Syria",
    isd: "963",
    code1: "SYR",
    code2: "SY"
},
{
    name: "Taiwan",
    isd: "886",
    code1: "TWN",
    code2: "TW"
},
{
    name: "Tajikistan",
    isd: "992",
    code1: "TJK",
    code2: "TJ"
},
{
    name: "Tanzania",
    isd: "255",
    code1: "TZA",
    code2: "TZ"
},
{
    name: "Thailand",
    isd: "66",
    code1: "THA",
    code2: "TH"
},
{
    name: "Togo",
    isd: "228",
    code1: "TGO",
    code2: "TG"
},
{
    name: "Tokelau",
    isd: "690",
    code1: "TKL",
    code2: "TK"
},
{
    name: "Tonga",
    isd: "676",
    code1: "TON",
    code2: "TO"
},
{
    name: "Trinidad and Tobago",
    isd: "1868",
    code1: "TTO",
    code2: "TT"
},
{
    name: "Tunisia",
    isd: "216",
    code1: "TUN",
    code2: "TN"
},
{
    name: "Turkey",
    isd: "90",
    code1: "TUR",
    code2: "TR"
},
{
    name: "Turkmenistan",
    isd: "993",
    code1: "TKM",
    code2: "TM"
},
{
    name: "Turks and Caicos Islands",
    isd: "1649",
    code1: "TCA",
    code2: "TC"
},
{
    name: "Tuvalu",
    isd: "688",
    code1: "TUV",
    code2: "TV"
},
{
    name: "United Arab Emirates",
    isd: "971",
    code1: "ARE",
    code2: "AE"
},
{
    name: "Uganda",
    isd: "256",
    code1: "UGA",
    code2: "UG"
},
{
    name: "United Kingdom",
    isd: "44",
    code1: "GBR",
    code2: "GB"
},
{
    name: "Ukraine",
    isd: "380",
    code1: "UKR",
    code2: "UA"
},
{
    name: "Uruguay",
    isd: "598",
    code1: "URY",
    code2: "UY"
},
{
    name: "United States",
    isd: "1",
    code1: "USA",
    code2: "US"
},
{
    name: "Uzbekistan",
    isd: "998",
    code1: "UZB",
    code2: "UZ"
},
{
    name: "Vanuatu",
    isd: "678",
    code1: "VUT",
    code2: "VU"
},
{
    name: "Vatican",
    isd: "379",
    code1: "VAT",
    code2: "VA"
},
{
    name: "Venezuela",
    isd: "58",
    code1: "VEN",
    code2: "VE"
},
{
    name: "Vietnam",
    isd: "84",
    code1: "VNM",
    code2: "VN"
},
{
    name: "U.S. Virgin Islands",
    isd: "1340",
    code1: "VIR",
    code2: "VI"
},
{
    name: "Wallis and Futuna",
    isd: "681",
    code1: "WLF",
    code2: "WF"
},
{
    name: "Western Sahara",
    isd: "212",
    code1: "ESH",
    code2: "EH"
},
{
    name: "Yemen",
    isd: "967",
    code1: "YEM",
    code2: "YE"
},
{
    name: "Zambia",
    isd: "260",
    code1: "ZMB",
    code2: "ZM"
},
{
    name: "Zimbabwe",
    isd: "263",
    code1: "ZWE",
    code2: "ZW"
}];

  constructor(
    public loadingController: LoadingController,
      public modalController: ModalController,
      public menuController: MenuController,
      public pickerController: PickerController,
      private dataService: AppDataService,
      private router: Router
  ) { }



  //install prompt
  installPromptInit(value:any) {
    this.deferredPrompt = value;
    timer(1000).subscribe(() => this.dataService.installPrompt(true));
  }

  showInstallBanner() {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice
      .then((choiceResult:any) => {
        if (choiceResult.outcome === 'accepted') {
          //firebase.analytics().logEvent('InstalledApp', { clicked: true });
        } else {
          //firebase.analytics().logEvent('InstalledApp', { clicked: false });
        }
        this.deferredPrompt = null;
      });

  }


  // detect platform
  private platform = new BehaviorSubject([]);
  platformDevice = this.platform.asObservable();

  platformDetect(platform: any) {
    this.platform.next(platform)
  }

  getPlatform() {

    return new Promise(async (resolve,reject) => {

        this.platformDevice.subscribe(res => {

              for(let platform of res){

                  switch(platform){
                    case 'mobile':
                      resolve({
                        desktop: false,
                        mobile: true,
                        tablet: false
                      });
                    break;
                    case 'tablet':
                      resolve({
                        desktop: false,
                        mobile: false,
                        tablet: true
                      });
                    break;
                    case 'desktop':
                      resolve({
                        desktop: true,
                        mobile: false,
                        tablet: false
                      });
                    break;

                  }
              }

        });

      });
      
  }
  

  // menu
  async closeMenu(){
    await this.menuController.close();
  }


// modal
async setupModal(action: any, style:string) {

  switch(action){
    case 'ScannerComponent':
      this.presentModal(ScannerComponent, style);
    break;
    default:
    this.presentModal(NavigationComponent, style);
  }

}

  async presentModal(component:any, style:string) {
    const modal = await this.modalController.create({
      component: component,
      cssClass: style,
      backdropDismiss: false
    });
    return await modal.present();
  }

  async dismissModal(){
    await this.modalController.dismiss();
  }




  async openPicker(numColumns: any , numOptions: any, columnOptions: any, type: string, label: string){

      const picker = await this.pickerController.create({
      columns: this.getColumns(numColumns, numOptions, columnOptions),
      buttons: [
        {
          text: label,
          handler: (value) => {

            console.log(value[0].text);

          }
        }
      ]
    });
    await picker.present();
  }
  
  getColumns(numColumns: any, numOptions: any, columnOptions: any) {
    let columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push({
        name: i,
        options: this.getColumnOptions(i, numOptions, columnOptions)
      });
    }
  
    return columns;
  }
  
  getColumnOptions(columnIndex: any, numOptions: any, columnOptions: any) {
    let options = [];
    for (let i = 0; i < numOptions; i++) {
      options.push({
        text: columnOptions[columnIndex][i % numOptions],
        value: i
      })
    }
  
    return options;
  }



  //loading
  async presentLoading(config: any) {
    const loading = await this.loadingController.create(config);
    return await loading.present();
  }


  async dismissLoading(){
    await this.loadingController.dismiss();
  }



  // link control
  linkControl(type: string, link:string){
    switch(type){
      case 'external':
        window.open(
          link,
          '_blank'
        );
      break;
      default:
        this.router.navigate(['/' + link]);

    }
}


  
}
