import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

    // app ready
    private ready = new BehaviorSubject(false);
    appReady = this.ready.asObservable();

    // global modules
    private global = new BehaviorSubject([]);
    globalStructure = this.global.asObservable();

    // app ready
    private showSplash = new BehaviorSubject(true);
    showSplash$ = this.showSplash.asObservable();
  
    // user data
    private user = new BehaviorSubject([]);
    userState = this.user.asObservable();

    // owner data
    private owner = new BehaviorSubject([]);
    owner$ = this.owner.asObservable();

    // bike registration data
    private registrations = new BehaviorSubject([]);
    registrations$ = this.registrations.asObservable();

    // app messages
    private messages = new BehaviorSubject([]);
    messagesStructure = this.messages.asObservable();

    // menu state
    private menu = new BehaviorSubject(false);
    menuState = this.menu.asObservable();

    // install app
    private install = new BehaviorSubject(false);
    installValue = this.install.asObservable();

    // access code
    private accessCode = new BehaviorSubject(null);
    accessCode$ = this.accessCode.asObservable();

    // frame qr
    private qrCode = new BehaviorSubject(null);
    qrCode$ = this.qrCode.asObservable();

    // app state
    appStatus(ready: boolean) {
      this.ready.next(ready)
    }

    // globals
    globalData(global: any) {
      this.global.next(global)
    }

    // splash state
    splashStatus(showSplash: boolean) {
      this.showSplash.next(showSplash)
    }

    // user
    userData(user: any) {
      this.user.next(user)
    }

    // owner
    ownerData(owner: any) {
      this.owner.next(owner)
    }

    // registrations
    registrationData(registrations: any) {
      this.registrations.next(registrations)
    }

    // messages
    messagesData(messages: any) {
      this.messages.next(messages)
    }

    // menu state
    menuStatus(menu: boolean) {
      this.menu.next(menu)
    }

    // install
    installPrompt(install: boolean) {
      this.install.next(install)
    }

    // access state
    accessCodeStatus(accessCode: boolean) {
      this.accessCode.next(accessCode)
    }

    // qr state
    qrCodeStatus(qrCode: any) {
      this.qrCode.next(qrCode)
    }


}
