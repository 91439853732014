import { Component, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { FirebaseService } from './services/firebase.service';
import { UtilitiesService } from './services/utilities.service';
import { AuthenticationService } from "./services/authentication.service";
import { AppDataService } from './services/app-data.service';
import { InstallService } from './services/install.service';
import { timer } from 'rxjs';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger('fadeInOn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.2s', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.2s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AppComponent {
  public appPages = [
    { title: 'Welcome', url: '/dashboard', icon: 'grid' },
    { title: 'Bike Registration', url: '/bike-registration', icon: 'reader' },
    { title: 'Technical', url: '/technical', icon: 'settings' },
    { title: 'Profile', url: '/owner', icon: 'person' }
  ];

  public altNav = [
    { title: 'Sign Out', url: '/login', icon: 'exit' }
  ];

  private navigation: any;
  public menuState: boolean = false;
  public menuIsOpenState: boolean = false;
  public userData: any;
  public showSplash: boolean = true;
  public isMobile: boolean = false;

  constructor(
    private platform: Platform,
    private firebaseService: FirebaseService,
    private utilitiesService: UtilitiesService,
    public installService: InstallService,
    private authService: AuthenticationService,
    private dataService: AppDataService,
    private menu: MenuController
  ) {
    this.initializeApp();
  }


    // init
    initializeApp() {
      this.platform.ready().then(() => {

            //this.menuState = true;
            this.menuStatus();

            this.dataService.showSplash$.subscribe((res:boolean) => {
                this.showSplash = res;
            });

            //this.installService.checkForInstall();
            this.utilitiesService.platformDetect(this.platform.platforms());
            this.setDataStructure();

            for(let platform of this.platform.platforms()){

              if(platform === 'mobile'){
                this.isMobile = true;
               // this.menuState = true;
              }

            }


      });
    }


    setDataStructure(){ 
      this.firebaseService.getGlobals().then((res:any) => {
        this.navigation = res[0].navigation;
        this.dataService.globalData(res[0]);
        this.userAuthState();
      });
    }


    userAuthState(){
        this.dataService.userState.subscribe((res:any) => {

            if(res === null){
                this.menuState = false;
                timer(2000).subscribe(() => {
                  this.dataService.splashStatus(false);
                });
            } 

        });
    }


    menuStatus(){
      this.dataService.menuState.subscribe(data => {
        this.menuState = data;
      });
    }


    menuIsOpen(event: boolean){
      this.menuIsOpenState = event;
    }


    signOut(){
      this.authService.signOut();
    }

    showNavigation(){
      this.utilitiesService.setupModal('NavigationComponent', 'modal');
    }


}
