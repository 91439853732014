import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { AppDataService } from './app-data.service';
import { FirebaseService } from './firebase.service';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  private owner = new BehaviorSubject([]);
  owner$ = this.owner.asObservable();

  constructor(
    public dataService: AppDataService,
    public firebaseService: FirebaseService,
    public router: Router
  ) { }


  ownerData(owner: any) {
    this.owner.next(owner)
  }


  getOwnerInit(id:string){

    this.firebaseService.getOwner(id).then((res:any) => {
      this.getOwnerProfile();
    });

  }


  getOwnerProfile(){

    this.dataService.owner$.subscribe((res:any) => {

      this.ownerData(res[0]);

      if(res.length === 0){

        timer(0).subscribe(() => {
          this.router.navigate(['owner']); 
        });

      } 

      this.getOwnerRegistrations(res[0].uid);
      timer(1000).subscribe(() => {
        this.router.navigate(['dashboard']);
      });
      
       


    });

  }
  


  getOwnerRegistrations(uid: string){

    this.firebaseService.getRegistrations(uid).then(() => {
      this.dataService.menuStatus(true);
      timer(2000).subscribe(() => {
       // this.router.navigate(['dashboard']);
      });

    });

  }


  updateRegistration(){

    timer(2000).subscribe(() => {
      this.dataService.splashStatus(false);
    });

  }





}
