import { Component, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AppDataService } from 'src/app/services/app-data.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit {

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;
  scannerActive: boolean = true;

  availableDevices: MediaDeviceInfo[];

  constructor(
    public utilitiesService: UtilitiesService,
    public dataService: AppDataService
  ){

  }

  ngOnInit() {
 
  }

  onCodeResult(resultString: string) {
    console.log(resultString);
    this.dataService.qrCodeStatus(resultString);
    this.stopScan();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    console.log(devices);
    this.scannerActive = true;
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  stopScan(){
    timer(500).subscribe(() => {
      this.scannerActive = false;
      this.scanner.scanStop();
      this.utilitiesService.dismissModal();
    });
  }
  
}
