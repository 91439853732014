import { Injectable } from '@angular/core';
import { AppDataService } from '../services/app-data.service';
import { UtilitiesService } from '../services/utilities.service';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstallService {

  constructor(
    public dataService: AppDataService,
    public utilitiesService: UtilitiesService
  ) { }


  public checkForInstall(){
      window.addEventListener('beforeinstallprompt', (event: any) => {
        console.log('window event', event);
        event.preventDefault();
        this.utilitiesService.installPromptInit(event);
      });
  };


}