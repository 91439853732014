import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { Auth, authState, signOut, User, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, updateProfile, updateEmail, sendPasswordResetEmail, applyActionCode, signInWithPhoneNumber} from '@angular/fire/auth';
import { AppDataService } from './app-data.service';
import { OwnerService } from './owner.service';
import { EMPTY, Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { traceUntilFirst } from '@angular/fire/performance';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly userDisposable: Subscription|undefined;
  public readonly user: Observable<User | null> = EMPTY;
  public updateValue: any;
  public verificationId: any;

  constructor(
    private auth: Auth,
    public dataService: AppDataService,
    private ownerService: OwnerService,
    public router: Router,  
    public ngZone: NgZone
  ) { 

    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {

        switch(isLoggedIn){
            case false:

              this.dataService.userData(null);
              localStorage.setItem('user', null);
              this.dataService.menuStatus(false);

            break;
            default:

              localStorage.setItem('user', JSON.stringify(this.auth.currentUser));
              this.dataService.userData(this.auth.currentUser);
    
              if(this.auth.currentUser.phoneNumber){
                  this.ownerService.getOwnerInit(this.auth.currentUser.uid);
              } else {
                  this.dataService.menuStatus(false);
              }

        }

      });
    }

  }


  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }


  // Register / Sign in with phone & code
  signInPhoneAuth(value: any, recaptchaVerifier: any) {


    console.log('my phone',  value.code + value.phone);
    return new Promise(async (resolve,reject) => {

      signInWithPhoneNumber(this.auth, value.code + value.phone , recaptchaVerifier)
      .then((res) => {

        this.updateValue = value;
        this.verificationId = res;
        resolve(res);

      }).catch((error) => {
        resolve(error);
      })

    });

  }


  // then access with auth code sent to phone
  signInCode(code:any){

    this.verificationId.confirm(code.accessCode).then((res:any) => {

      if(code.login == false){
        this.updateUser().then(() => {
          this.dataService.accessCodeStatus(true);
        });
      }
 
      }).catch(() => {
        this.dataService.accessCodeStatus(false);
      });

  }


  //update user
  updateUser() {
    return new Promise(async (resolve,reject) => {
        updateProfile(this.auth.currentUser, {displayName: this.updateValue.name}).then(() => {

          updateEmail(this.auth.currentUser, this.updateValue.email).then(() => {
            this.router.navigate(['owner']);
            resolve({success: true});
          })

        }).catch((error) => {
          resolve({message: error});
        })
    });
  }
  
 
  // Sign-out 
  async signOut() {
    await signOut(this.auth).then(() => {
      this.dataService.menuStatus(false);
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    })
  }



}
