import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AppDataService } from '../../services/app-data.service';
import { IonContent } from '@ionic/angular';
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})

export class NavigationComponent implements OnInit {

  @ViewChild(IonContent, { static: false }) content: IonContent;

  public isDesktop: boolean;
  public isMobile: boolean;
  public isTablet: boolean;
  public navigation: any[];
  public socialLinks: any[];
 
  constructor(
    public modalController: ModalController,
    private utilitiesService: UtilitiesService,
    private dataService: AppDataService,
    private authService: AuthenticationService
  ) { }


  ngOnInit() {

    this.dataService.globalStructure.subscribe((res:any) => {

        this.navigation = res.navigation;

        // get platform
        this.utilitiesService.getPlatform().then((res: any) => {
          this.isDesktop = res.desktop;
          this.isMobile = res.mobile;
          this.isTablet = res.tablet;
        });

    });

  }


  dismissModal() {
    this.modalController.dismiss();
  }

  signOut(){
    this.authService.signOut();
    this.modalController.dismiss();
  }


}
