import { Injectable } from '@angular/core';
import { Observable, timer  } from 'rxjs';
import { Firestore, collectionData, collection, doc, docData, addDoc, updateDoc, query, where, orderBy, getDocs, onSnapshot } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { AppDataService } from './app-data.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    public firestore: Firestore,
    public dataService: AppDataService,
    public functions: Functions
  ) { }


  // get globals
  async getGlobals(){

    return new Promise(async (resolve,reject) => {

      const q = query(collection(this.firestore, 'globals'));
      onSnapshot(q, (querySnapshot) => {
        const globals = [];
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          globals.push(obj);
        });
        this.dataService.globalData(globals);
        timer(2000).subscribe(() => {
          resolve(globals);
        });
        
      });

  });

    

  }



  // get owner
  async getOwner(id: string){

    return new Promise(async (resolve,reject) => {

      const q = query(collection(this.firestore, 'owners'), where('authId', '==', id));
      onSnapshot(q, (querySnapshot) => {
        const owner = [];
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          obj.uid = doc.id;
          owner.push(obj);
        });
        this.dataService.ownerData(owner);
        timer(2000).subscribe(() => {
          resolve(owner);
        });
        
      });

  });

    

  }

  // create owner
  createOwner(owner:any){

    return new Promise(async (resolve,reject) => {

        addDoc(collection(this.firestore, 'owners'), owner).then((res:any) => {
            resolve({success: true});
        }).catch(() => {
            reject({success: false});
        });

    });

  }


  // update owner
  updateOwner(owner:any){

    return new Promise(async (resolve,reject) => {

        updateDoc(doc(this.firestore, 'owners', owner.uid), owner).then(() => {
            resolve({success: true});
        }).catch(() => {
            reject({success: false});
        });

    });

  }




  // get registrations
  async getRegistrations(id: string){

    const q = query(collection(this.firestore, 'bike_registrations'), where('ownerId', '==', id));
    onSnapshot(q, (querySnapshot) => {
      const registrations = [];
      querySnapshot.forEach((doc) => {
        let obj = doc.data();
        obj.uid = doc.id;
        registrations.push(obj);
      });
      this.dataService.registrationData(registrations);
    });

  }



  // create registration
  async createRegistration(bike:any){
    const docRef = await addDoc(collection(this.firestore, 'bike_registrations'), bike);
    return {
      success: true,
      docId: docRef.id
    }
  }


    // update registration
    updateRegistration(id:string, bike:any){

      return new Promise(async (resolve,reject) => {
          updateDoc(doc(this.firestore, 'bike_registrations', id), bike).then(() => {
              resolve({success: true});
          }).catch(() => {
              reject({success: false});
          });
      });
  
    }




  //transactional emails
  sendEmail(value: any, template:string) {
    const addMessage = httpsCallable(this.functions, template);
    addMessage(value).then((res:any) => {
      console.log(res);
    });
  }













}


